<template>
  <v-form ref="form_equipment">
    <v-card-text>
      <v-row dense>
        <v-col cols="1">
          <v-text-field label="Id" outlined readonly :disabled="!currentItem.id" v-model="currentItem.id"></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field label="Nome" outlined readonly v-model="currentItem.descricao.nome"></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-text-field  label="Patrimônio" outlined v-model="currentItem.patrimonio" type="Number" ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-select label="Deposito" outlined v-model="currentItem.deposito" :items="deposito" item-text="nome" item-value="id" :loading="loadingDeposito" ></v-select>
        </v-col>
        <v-col cols="2">
          <v-select label="Disponibilidade" outlined v-model="currentItem.disponibilidade" :items="disponibilidade" item-text="nome" item-value="id" :loading="loadingDisponibilidade" ></v-select>
        </v-col>
        <v-col cols="2">
          <v-select label="Próprio / Alugado" outlined v-model="currentItem.propriedade" :items="propriedadeOptions" ></v-select>
        </v-col >
      </v-row>
      <v-row dense>
        <v-col cols="2">
          <vuetify-money  v-model="currentItem.aquisicao.valor"  label="Valor Aquisição"  outlined  clearable  v-money="currencyMask"  :options="options" ></vuetify-money>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-btn-toggle v-model="isActive" mandatory class="white--text">
            <v-btn :color="!isActive ? 'error' : ''">
              Inativo
            </v-btn>
            <v-btn :color="isActive ? 'green' : ''">
              Ativo
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col class="text-end">
          <ConfirmButton color="success" :loading="loading">
            Salvar
          </ConfirmButton>
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>

<script>
import api from '../../../../http';
import ConfirmButton from '../../../ConfirmButton.vue';

export default {
  name: "Edit",
  components: { ConfirmButton },
  props: {
    currentItem: {
      type: Object,
      default: () => ({
        disponibilidade: null,
        propriedade: null,
        aquisicao: {
          valor: null  // Inicializa a propriedade aquisicao
        }
      })
    },
    onSubmit: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    disponibilidade: [],
    deposito: [],
    loadingDisponibilidade: true,
    loadingDeposito: true,
    isActives: true,
    propriedadeOptions: [
      { text: 'Próprio', value: true },
      { text: 'Alugado', value: false },
    ],
    options: {
      locale: "pt-BR",
      prefix: "R$",
      suffix: "",
      length: 11,
      precision: 2
    },
    currencyMask: {
      decimal: ',',
      thousands: '.',
      precision: 2,
    },
  }),
  methods: {
     async getDisponibilidade() {
      this.loadingDisponibilidade = true;
      try {
        const { data } = await api.get(`/uteis/disponibilidades/`);
        this.disponibilidade = data.filter(item => item.ativo)
        // console.log(this.disponibilidade)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.loadingDisponibilidade = false;
      }
    },
    async getDeposito() {
      this.loadingDeposito = true;
      try {
        const { data } = await api.get("/uteis/deposito/");
        this.deposito = data.filter(item => item.ativo);
        // console.log(this.deposito)
      }
      catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      }
      finally {
        this.loadingDeposito = false;
      }
    },

  },
  computed: {
    isActive: {
      get() {
        return this.currentItem.ativo ? 1 : 0
      },
      set(value) {
        return (this.currentItem.ativo = value)
      }
    },
  },
  mounted() {
    this.getDisponibilidade();
    this.getDeposito();
  }
}
</script>
