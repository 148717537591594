<template>
  <v-row v-if="isLoading" class="d-flex justify-center py-8">
    <v-progress-circular indeterminate />
  </v-row>
  <v-row v-else dense>
    <v-col cols="12">
      <h4><b>Detalhamento do Equipamento:</b></h4>
      <v-container>
        <v-row v-for="equipamento in equipamentos" :key="equipamento.id">
          <v-col cols="12" md="6">
            <p><b>ID:</b> {{ equipamento.id }}</p> 
            <p><b>Nome:</b> {{ equipamento.descricao.nome }}</p>
            <p><b>Disponibilidade:</b> {{ equipamento.disponibilidade.nome }}</p>
            <p><b>Data de Aquisição:</b> {{ equipamento.propriedade ? 'Equipamento Locado':  formatDate(equipamento.aquisicao.dataAquisicao) }}</p>
            <p><b>Valor de Aquisição:</b> R$ {{ equipamento.propriedade ? 'Equipamento Locado': equipamento.aquisicao.valor }}</p>
            <p><b>Data de Validade da Garantia:</b> {{ equipamento.propriedade ? 'Equipamento Locado': formatDate(equipamento.aquisicao.dataValidadeGarantia) }}</p>
            <p><b>Depósito:</b> {{ equipamento.propriedade ? 'Equipamento Locado':  equipamento.deposito.nome }}</p>
          </v-col>
          <v-col cols="12" md="6">
            <h4><b>Itens Esterilizáveis Associados:</b></h4>
            <v-list dense>
              <v-list-item v-for="item in equipamento.esterelizavel" :key="item.id">
                <v-list-item-content>
                  <v-list-item-title>{{ item.nome.nome }}</v-list-item-title>
                  <!-- <v-list-item-subtitle>
                    <p></p>
                    <b> - Patrimônio:</b> {{ item.patrimonio }} || 
                    <b>Data de Aquisição:</b> {{ formatDate(item.aquisicao.dataAquisicao) }} || 
                    <b>Valor:</b> R$ {{ item.aquisicao.valor }}
                  </v-list-item-subtitle> -->
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

  
<script>
import api from "../../../../http";

export default {
  name: "EquipamentosExpand",
  data: () => ({
    isLoading: true,
    equipamentos: [],
  }),
  props: {
    item: Object,
  },
  methods: {
    async getEquipments() {
      try {
        const response = await api.get('equipamentos/equipamentos/');
        this.equipamentos = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    formatDate(dateString) {
      if (!dateString) return '';
      const [year, month, day] = dateString.split('-');
      return `${day}/${month}/${year}`;
    },
  },
  mounted() {
    this.getEquipments();
  },
};
</script>


  
<style scoped>
.text {
  color: red;
}
</style>
  